import React, { useState } from 'react'

import { Link, NavLink } from 'react-router-dom'

// Import assets
import logo from '../assets/img/full_logo_noback.png'

// Import icons
import { FaTimes, FaBars } from 'react-icons/fa'

// Import CSS
import './css/navbar.css'

const Navbar = () => {

    // Variable to store mobile nav open/close
    const [mobileNav, setMobileNav] = useState(false)

    const handleBurger = () => {

        if (mobileNav === false) {

            setMobileNav(true)

        } else {

            setMobileNav(false)

        }
    }

  return (
    <>
    <nav>
        <Link href='#'><img src={logo} alt="logo" className='navbar-image' /></Link>
        <div>
            <ul id='navbar' className={mobileNav ? '#navbar active' : '#navbar'}>
                <li><NavLink to="/" onClick={() => setMobileNav(false)}>Home</NavLink></li>
                <li><NavLink to="/gallery" onClick={() => setMobileNav(false)}>Gallery</NavLink></li>
                <li><NavLink to="/testimonials" onClick={() => setMobileNav(false)}>Testimonials</NavLink></li>
                <li><NavLink to="/contact" onClick={() => setMobileNav(false)}>Contact</NavLink></li>
            </ul>
        </div>
        <div id='mobile' onClick={handleBurger}>
            <i id="bar">{mobileNav ? <FaTimes /> : <FaBars />}</i>
        </div>
    </nav>
    </>
  )
}

export default Navbar