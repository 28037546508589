import React, { useState } from 'react';
// Import Chakra
import {
  Grid,
  GridItem,
} from '@chakra-ui/react'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// ===== Import Elements ======

// Import Navbar
import Navbar from './components/Navbar';

// Desktop Pannel (left pannel)
import LeftPanel from './components/LeftPanel';

// Home
import Home from './components/Home';

// Gallery
import Gallery from './components/Gallery';

// Testimonials
import Testimonials from './components/Testimonials';

// Contact
import Contact from './components/Contact';

// ===== Import Elements END =====

// Import CSS
import './App.css';

const App = () => {
  return (
    <>
    <Router>

      <Navbar />

      <Grid className='main-grid'>
        
        <GridItem className='left-pannel'>
          <LeftPanel />
        </GridItem>
        
        <GridItem className='right-pannel'>

            <Routes>

              <Route exact path='/' element={<Home />} />
              <Route exact path='/gallery' element={<Gallery />} />
              <Route exact path='/testimonials' element={<Testimonials />} />
              <Route exact path='/contact' element={<Contact />} />

            </Routes>

        </GridItem>

      </Grid>

    </Router>
    </>
  )
}

export default App