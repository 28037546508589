import React, { useState } from 'react'

import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'

// Import Icons
import { AiFillCloseCircle } from 'react-icons/ai'
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs'

// Import Gallery
import Images from '../assets/gallery/Gallery'

// Import CSS
import './css/gallery.css'

// Test Images
// const Images = [
//     "https://picsum.photos/2000/3000",
//     "https://picsum.photos/3000/2000",
//     "https://picsum.photos/4000/3000",
//     "https://picsum.photos/3000/1500",
//     "https://picsum.photos/1000/2500",
//     "https://picsum.photos/1500/2000",
//     "https://picsum.photos/1000/2000",
//     "https://picsum.photos/1500/4000",
//     "https://picsum.photos/2000/3000",

// ]

const Gallery = () => {

    // Const to store clicked photo data
    const [data, setData] = useState({img: '', i: 0})

    const viewImage = (img, i) => {
        setData({img, i})
    }

    const imageAction = (action) => {
        let i = data.i

        if (action === 'next-img') {
            setData({img: Images[i + 1], i: i + 1})
        } else if (action === 'prev-img') {
            setData({img: Images[i - 1], i: i -1})
        } else if (action === 'close') {
            setData('', 0)
        }

    }

  return (
    <>
        {
        data.img &&
            <div className='image-view'>
                {/* Close button */}
                <button className='close' onClick={() => imageAction('close')}><AiFillCloseCircle /></button>
                <button className="arrow previous" onClick={() => imageAction('prev-img')}><BsFillArrowLeftCircleFill /></button>
                <img src={data.img} alt={`"image-${data.i}"`} />
                <button className="arrow next" onClick={() => imageAction('next-img')}><BsFillArrowRightCircleFill /></button>
            </div>
        }
        <div className='gallery-box'>
            <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
            >
                <Masonry gutter='1rem'>
                    {Images.map((image, i) => (
                        <img
                            key={i}
                            src={image}
                            style={{width: "100%", display: "block", cursor: 'pointer'}}
                            alt=""
                            onClick={() => viewImage(image, i)}
                        />
                    ))}
                </Masonry>
            </ResponsiveMasonry>
        </div>
    </>
  )
}

export default Gallery