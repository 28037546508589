import React, { useRef } from 'react'
import emailjs from 'emailjs-com'

import { GiRotaryPhone } from 'react-icons/gi'
import { BsTelephoneFill, BsTwitter, BsFacebook, BsLinkedin } from 'react-icons/bs'

// Import CSS
import './css/contact.css'

const { REACT_APP_EMAIL_JS_PUBLIC } = process.env

const Contact = () => {

    const form = useRef()

    const sendEmail = (e) => {

        e.preventDefault();

        emailjs.sendForm('service_cbes8a8', 'template_h31mzio', form.current, REACT_APP_EMAIL_JS_PUBLIC)
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });

        e.target.reset()
        
    }

    return (
        <>
        <div className="content">

            <div className="box">
                <h2>Contact</h2>

                <h3>For Bookings and Enquiries Contact us on...</h3>
                <ul className="phone">
                    <li><i><GiRotaryPhone /></i><a href='tel:020 373 24667'>020 373 24667</a></li>
                    <li><i><BsTelephoneFill /></i><a href='tel:07591 833023'>07591 833023</a></li>
                </ul>

                <h3>Keep up to date on the latest special offers via our socials!</h3>
                <ul className="socials">
                    <li><i><BsTwitter /></i><a href='https://twitter.com/HKCityContract' target="_blank">@HKCityContract</a></li>
                    <li><i><BsFacebook /></i><a href="https://www.facebook.com/people/HK-City-Contractors/100089996290574/" target="_blank">H&K City Contractors</a></li>
                    <li><i><BsLinkedin /></i><a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGfnQvkkcbOhwAAAYZ6ShHAN7JMCng-oswpVBCYFLefSm-6JsdftTx4IWEz5S1mob1_MR5xXUUSMAa_6D0DM9OtykltlQTQp6cQWRkmVHSS8u39E2g0mVPS_WPAe3pQjpvU2gA=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fhnk-city-contractors-892334265%2F" target="_blank">HnK City Contractors</a></li>
                </ul>

            </div>

            <div className="box">
                <h2>Get a Quote</h2>
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name='name' placeholder='Name' />
                    <input type="text" name='reply_to' id='reply_to' placeholder='Email' />
                    <input type="text" name='phone' inputMode='numeric' placeholder='Phone Number' />
                    <input type="text" name='subject' placeholder='Subject' />
                    <textarea name='message' cols="30" rows="10" placeholder='Message..'></textarea>
                    <input type='submit' className="btn btn-primary" value='Send' />
                </form>
            </div>

        </div>
        </>
    )
}

export default Contact