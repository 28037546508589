import React, { useState, useEffect } from 'react'
import { Grid, GridItem } from '@chakra-ui/react';
import { Rating } from '@mui/material';

import LimitedWordTextarea from './elements/LimitedWordTextarea';

// Import CSS
import './css/testimonials.css'

const { REACT_APP_API_URL } = process.env

const Testimonials = () => {

    
    // Get reviews from reviews API
    useEffect( () => {
        fetchReviews()
    }, [])

    const [reviews, setReviews] = useState([])

    const fetchReviews = async () => {
        const reviewsData = await fetch(`${REACT_APP_API_URL}/reviews`, {
            method: 'GET',
        }).then( res => res.json())

        setReviews(reviewsData)
        
    }

    // Set default stars to 5
    const [stars, setStars] = useState(5);

    return (
        <>
        <div className="content">

            <div className="box" id='review-box'>

                <h2>Leave a Review</h2>

                <form method='POST' action={`${REACT_APP_API_URL}/addReview`} className='form'>
                    <input type="name" placeholder='You name' id='new-name' name='name' />
                    <label htmlFor="Rate your experience">Rate you experience...</label>
                    <Rating
                        name="rating"
                        id="new-rating"
                        size="large"
                        value={stars}
                        onChange={(event, newValue) => {
                        setStars(newValue);
                        }}
                    />
                    <LimitedWordTextarea name='review' limit={60} value="" placeholder='Review your experience' rows={6} />
                    {/* <textarea rows={7} name='review' placeholder='Review your experience' /> */}
                    <input type="text" name='summery' id='new-summery' placeholder='Summarise your experience' />
                    <button type='submit' className="btn btn-primary">Leave Review</button>
                </form>

            </div>

            <Grid className='reviews'>

                {reviews.slice(0).reverse().map((review, index) => {

                    return (
                    <GridItem className='review-item' key={index}>
                        
                        <div className="box">
                            <h4 className='review-name'>{review.name}</h4>
                            <h2 className='review-title'>{review.summery}</h2>
                            <Rating name={`"rating-${review.index}"`} defaultValue={review.rating} precision={1} readOnly className='stars' />  
                            <p className='review-body'>{review.body}</p>
                        </div>
                    
                    </GridItem>
                    )

                })}

            </Grid>

        </div>
        </>
    )
}

export default Testimonials

