import React, { useState, useCallback, useEffect } from 'react'

// Limited Text Area
const LimitedWordTextarea = ({ name, rows, cols, value, limit, placeholder, id }) => {
    const [{ content, wordCount }, setContent] = React.useState({
      content: value,
      wordCount: 0
    });
  
    const setFormattedContent = React.useCallback(
      text => {
        let words = text.split(' ').filter(Boolean);
        if (words.length > limit) {
          document.getElementById(id).addEventListener('keypress', (e) => {
            e.preventDefault()
          })
          // setContent({
          //   content: words.slice(0, limit).join(' '),
          //   wordCount: limit
          // });
        } else {
          setContent({ content: text, wordCount: words.length });
        }
      },
      [limit, setContent]
    );
  
    React.useEffect(() => {
      setFormattedContent(content);
    }, []);
  
    return (
      <>
        <textarea
          name={name}
          rows={rows}
          cols={cols}
          onChange={event => setFormattedContent(event.target.value)}
          value={content}
          placeholder={placeholder}
          id={id}
        />
        <p>
          {wordCount}/{limit}
        </p>
      </>
    );
  };

  export default LimitedWordTextarea