import React, { useEffect, useState } from 'react'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

// Import image
import logo from '../assets/img/full_logo_noback.png'

// Import gallery
import Images from '../assets/gallery/Gallery'

// Chakra Import
import { Container } from '@chakra-ui/react';

import './css/leftpannel.css'

const LeftPanel = () => {
  const [collection, setCollection] = useState([{}])
  useEffect(() => {
    const tempCollection = []
    Images.map(image => tempCollection.push({ url: image, caption: "Caption one" }))
    setCollection(tempCollection)
  }, [Images]);

  // const spanStyle = {
  //   padding: '20px',
  //   background: '#efefef',
  //   color: '#000000'
  // }

  // const divStyle = {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   backgroundSize: 'cover',
  //   height: '400px'
  // }
  return (
    <>
    {/* <Container className='left-container' centerContent>
      <div className='slide-container'>
        <Slide>
          {collection.map((slideImage, index) => (
            <div key={index}>
              <div style={{...divStyle, 'backgroundImage': `url(${slideImage.url})`}}>
                <span style={spanStyle}>{slideImage.caption}</span>
              </div>
            </div>
          ))}
        </Slide>
      </div>
    </Container> */}
    <Container className='left-container' centerContent>
        <img src={logo} alt="" className="logo" />
        <h2 className='title'>HARRY HUSSEIN</h2>
        <h3 className="subheading">HOME IMPROVEMENTS & RENOVATIONS</h3>
    </Container>
    </>
  )
}

export default LeftPanel