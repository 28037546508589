const Images = [
    require('./gallery (1).jpg'),
    require('./gallery (2).jpg'),
    require('./gallery (3).jpg'),
    require('./gallery (4).jpg'),
    require('./gallery (5).jpg'),
    require('./gallery (6).jpg'),
    require('./gallery (7).jpg'),
    require('./gallery (8).jpg'),
    require('./gallery (9).jpg'),
    require('./gallery (10).jpg'),
    require('./gallery (11).jpg'),
    require('./gallery (12).jpg'),
    require('./gallery (13).jpg'),
    require('./gallery (14).jpg'),
    require('./gallery (15).jpg'),
    require('./gallery (16).jpg'),
    require('./gallery (17).jpg'),
    require('./gallery (18).jpg'),
    require('./gallery (19).jpg'),
    require('./gallery (20).jpg'),
    require('./gallery (21).jpg'),
    require('./gallery (22).jpg'),
    require('./gallery (23).jpg'),
    require('./gallery (24).jpg'),
    require('./gallery (25).jpg'),
    require('./gallery (26).jpg'),
    require('./gallery (27).jpg'),
    require('./gallery (28).jpg'),
    require('./gallery (29).jpg'),
    require('./gallery (30).jpg'),
    require('./kitchen01.jpg'),
    require('./kitchen02.jpg'),
    require('./kitchen03.jpg'),
]

export default Images