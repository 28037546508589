import React, { useRef } from 'react'

import { Link } from 'react-router-dom'

import './css/home.css'

const Home = () => {

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  return (
    <>

    <div className="content">

      <div className="box">

        <h2>Welcome to {windowSize.current[0] < 530 && <br />} H&K City Contractors</h2>

        <div className="services">

          <h4 className="subheading">Our Services</h4>
          <ul className='services-list'>
            <li> - Carpentry</li>
            <li> - Painting/Decorating</li>
            <li> - Tiling/Plumbing</li>
            <li> - Garden Maintenance</li>
            <li> - Kitchen Bathroom Installations</li>
          </ul>

        </div>

        <div className="cta">

          <div className="enquire">
            <Link to="contact" className="btn btn-left">Make an Enquire</Link>
          </div>

          <div className="review">
            <Link to="/testimonials" className="btn btn-primary btn-right">Leave a Review</Link>
          </div>
          

        </div>

        <div className="quote">

          <h2 className='subheading'>Click here for a {windowSize.current[0] < 530 && <br />}<Link to="/contact">FREE QUOTE</Link></h2>
          
        </div>
        
      </div>

      <div className='box'>

        <h2 className="title">About{windowSize.current[0] < 530 && <br />} H&K City Contractors</h2>
        <br />
        <p>At H&K City Contractors, we are led by Harry Hussein, a highly experienced professional with 35 years of expertise in the building industry. With a background in home improvements and renovations, Harry brings a wealth of knowledge and professionalism to our team. We take pride in delivering high-quality projects and are excited to work with you to bring your building projects to life.</p>

      </div>

    </div>
    </>
  )
}

export default Home